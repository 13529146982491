import RouterMultiGuard from 'vue-router-multiguard'

import { allowStaffOnly, authorizeFor, fetchAppBootData } from '@/router/guards'
import {
  loadPageAsync as load,
  disallowIfLoggedIn,
  disallowIfNotLoggedIn
} from '@hypefactors/shared/js/utils/routerUtilities'

export default [
  {
    path: '/onboarding/:userId',
    component: load('onboarding/Onboarding'),
    name: 'onboarding',
    props: true
  },
  {
    path: '/stream-onboarding',
    component: load('onboarding/StreamOnboarding'),
    name: 'stream-onboarding',
    redirect: { name: 'stream-onboarding.step_1' },
    children: [
      {
        name: 'stream-onboarding.step_1',
        path: 'acc',
        component: load('onboarding/stream-steps/StreamOnboardingStep1'),
        meta: { step: 1 }
      },
      {
        name: 'stream-onboarding.step_2',
        path: 'brand',
        component: load('onboarding/stream-steps/StreamOnboardingStep2'),
        meta: { step: 2 }
      },
      {
        name: 'stream-onboarding.step_3',
        path: 'stream',
        component: load('onboarding/stream-steps/StreamOnboardingStep3'),
        meta: { step: 3 }
      },
      {
        name: 'stream-onboarding.step_4',
        path: 'target',
        component: load('onboarding/stream-steps/StreamOnboardingStep4'),
        meta: { step: 4 }
      }
    ]
  },
  {
    path: '/invitations/accept',
    component: load('invitations/Accept'),
    name: 'invitations.accept'
  },
  {
    path: '/charts-demo',
    component: load('ChartsDemo')
  },
  {
    path: '/signup',
    component: load('onboarding/Signup'),
    name: 'signup'
  },
  {
    path: '/auth',
    component: load('auth/Auth', { domain: 'shared' }),
    meta: { pageTitle: 'Auth | Hypefactors' },
    children: [
      {
        path: '/login',
        name: 'login',
        component: load('auth/Login'),
        beforeEnter: disallowIfLoggedIn,
        meta: { pageTitle: 'Login | Hypefactors' }
      },
      {
        path: '/logout',
        name: 'logout',
        component: load('auth/Logout', { domain: 'shared' })
      },
      {
        path: '/password-recovery',
        name: 'password-recovery',
        component: load('auth/PasswordRecovery'),
        beforeEnter: disallowIfLoggedIn,
        meta: { pageTitle: 'Recover Password | Hypefactors' }
      },
      {
        path: '/password-recovery/:user_id/:code',
        name: 'password-recovery-finalize',
        component: load('auth/PasswordRecoveryFinalize'),
        meta: { pageTitle: 'Recover Password | Hypefactors' }
      },
      {
        path: '/activate/:userId/:activationToken',
        name: 'active-user',
        component: load('auth/ActivateUser'),
        props: true,
        meta: { pageTitle: 'Activate Account | Hypefactors' }
      },
      {
        name: 'lock-out',
        path: 'lock-out',
        component: load('auth/LockOut')
      }
    ]
  },
  {
    path: '/',
    component: load('App'),
    beforeEnter: RouterMultiGuard([disallowIfNotLoggedIn, fetchAppBootData]),
    redirect: { name: 'dashboard.dashboard' },
    children: [
      {
        path: 'dashboard',
        component: load('dashboard/Dashboard'),
        meta: { section: 'dashboard', pageTitle: 'Dashboard | Hypefactors' },
        name: 'dashboard.dashboard'
      },
      {
        path: 'performance',
        component: load('performance/Performance'),
        name: 'performance',
        meta: {
          section: 'performance',
          pageTitle: 'Performance | Hypefactors'
        },
        redirect: { name: 'performance.facts' },
        children: [
          {
            path: 'facts',
            component: load('performance/Facts'),
            name: 'performance.facts',
            meta: {
              permission: 'performance-facts.list',
              permissionFallbackType: 'facts',
              permissionFallbackTo: { name: 'cx.measure' }
            }
          },
          {
            path: 'kpi',
            component: load('performance/kpi/Kpi'),
            meta: { section: 'performance', pageTitle: 'KPIs | Hypefactors' },
            children: [
              {
                name: 'performance.kpi.edit',
                path: ':kpiId/edit',
                props: true,
                component: load('performance/kpi/KpiEdit'),
                beforeEnter: authorizeFor(),
                meta: {
                  markAsActive: ['performance.kpi'],
                  permission: 'kpi.update'
                }
              },
              {
                name: 'performance.kpi',
                path: '',
                component: load('performance/kpi/KpiList'),
                meta: {
                  permission: 'kpis.list',
                  permissionFallbackType: 'kpi',
                  permissionFallbackTo: { name: 'cx.measure' }
                }
              }
            ]
          },
          {
            path: 'coverage',
            component: load('performance/coverage/Coverage'),
            name: 'performance.coverage',
            meta: {
              permission: 'clippings.list',
              permissionFallbackType: 'coverage',
              permissionFallbackTo: { name: 'cx.measure' }
            }
          },
          {
            path: 'coverage/clipping',
            component: load('performance/coverage/ClippingManagerIndex'),
            name: 'performance.coverage.clipping',
            meta: {
              blacklisted: true
            },
            children: [
              {
                path: 'prepare',
                component: load('performance/coverage/ClippingPrepare'),
                name: 'performance.coverage.clipping.prepare',
                meta: {
                  permission: 'create.manual.clipping',
                  permissionFallbackType: 'clipping_create',
                  permissionFallbackTo: { name: 'cx.measure' }
                }
              },
              {
                path: 'create',
                component: load('performance/coverage/ClippingManage'),
                name: 'performance.coverage.clipping.create'
              },
              {
                path: 'edit/:clippingId',
                component: load('performance/coverage/ClippingManage'),
                name: 'performance.coverage.clipping.edit',
                props: true
              },
              {
                path: 'assign/:clippingId',
                component: load('performance/coverage/ClippingAssign'),
                name: 'performance.coverage.clipping.assign'
              }
            ]
          }
        ]
      },
      {
        path: 'streams',
        component: load('streams/AuthorizedStreamsIndex'),
        meta: { section: 'streams', pageTitle: 'Streams | Hypefactors' },
        children: [
          {
            path: 'create',
            name: 'streams.create',
            props: true,
            component: load('streams/ManageStreams'),
            meta: {
              permission: 'stream.create',
              permissionFallbackType: 'stream_create',
              permissionFallbackTo: { name: 'cx.monitor' }
            }
          },
          {
            path: ':streamId/edit',
            name: 'streams.edit',
            props: true,
            component: load('streams/ManageStreams'),
            beforeEnter: authorizeFor(),
            meta: {
              permission: 'stream.update'
            }
          },
          {
            path: '/',
            name: 'streams.list',
            component: load('streams/Streams'),
            meta: {
              permission: 'streams.list',
              permissionFallbackType: 'streams',
              permissionFallbackTo: { name: 'cx.monitor' }
            },
            children: [
              {
                path: ':streamId',
                name: 'streams.mentions',
                component: load('streams/Mentions'),
                props: true,
                meta: {
                  markAsActive: ['streams.list'],
                  permission: 'streams.list'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'connect',
        component: load('connect/Root'),
        meta: { section: 'relations', pageTitle: 'Connect | Hypefactors' },
        name: 'connect',
        redirect: { name: 'connect.discover' },
        children: [
          {
            path: '/',
            name: 'connect.discover',
            component: load('connect/discover/Index'),
            meta: {
              permission: 'relations.list', // This is not true, but Casper wants Discover limited...
              permissionFallbackType: 'discover',
              permissionFallbackTo: { name: 'cx.connect' }
            }
          },
          {
            path: 'relations',
            component: load('connect/relations/_'),
            children: [
              {
                path: '',
                name: 'connect.relations',
                component: load('connect/relations/Index'),
                meta: {
                  permission: 'relations.list',
                  permissionFallbackType: 'relations',
                  permissionFallbackTo: { name: 'cx.connect' }
                }
              },
              {
                path: 'create',
                name: 'connect.relations.create',
                component: load('connect/relations/Create'),
                beforeEnter: authorizeFor(),
                meta: {
                  permission: 'relation.create',
                  permissionFallbackType: 'relation_create',
                  permissionFallbackTo: { name: 'cx.connect' },
                  markAsActive: 'connect.relations'
                }
              },
              {
                path: ':relationId',
                component: load('connect/relations/View'),
                meta: {
                  markAsActive: 'connect.relations'
                },
                children: [
                  {
                    path: '/',
                    name: 'connect.relation.view',
                    component: load('connect/relations/view/About')
                  },
                  {
                    path: 'activity',
                    name: 'connect.relation.activity',
                    component: load('connect/relations/view/Activity')
                  },
                  {
                    path: 'coverage',
                    name: 'connect.relation.coverage',
                    component: load('connect/relations/view/Coverage')
                  },
                  {
                    path: 'related',
                    name: 'connect.relation.related',
                    component: load('connect/relations/view/Related')
                  }
                ]
              },
              {
                path: ':relationId/edit',
                name: 'relations.edit',
                component: load('connect/relations/Edit'),
                props: true,
                meta: {
                  permission: 'relation.create',
                  markAsActive: 'connect.relations'
                }
              }
            ]
          },
          {
            path: 'groups',
            component: load('connect/groups/_'),
            children: [
              {
                path: '',
                name: 'connect.groups',
                component: load('connect/groups/Index'),
                meta: {
                  permission: 'relations.groups.list',
                  permissionFallbackType: 'relation_groups',
                  permissionFallbackTo: { name: 'cx.connect' }
                }
              },
              {
                path: 'create',
                name: 'connect.groups.create',
                beforeEnter: authorizeFor(),
                component: load('connect/groups/Manage'),
                meta: {
                  permission: 'relations.group.update',
                  markAsActive: 'connect.groups'
                }
              },
              {
                path: ':groupId',
                name: 'connect.groups.view',
                component: load('connect/groups/View'),
                props: true,
                beforeEnter: authorizeFor(),
                meta: {
                  permission: 'relations.group.view',
                  markAsActive: 'connect.groups'
                }
              },
              {
                path: ':groupId/edit',
                name: 'connect.groups.edit',
                component: load('connect/groups/Manage'),
                props: true,
                beforeEnter: authorizeFor(),
                meta: {
                  permission: 'relations.group.update',
                  markAsActive: 'connect.groups'
                }
              }
            ]
          },
          {
            path: 'facts',
            name: 'connect.facts',
            component: load('connect/facts/Index'),
            meta: {
              permission: 'emails.list',
              permissionFallbackType: 'relation_facts',
              permissionFallbackTo: { name: 'cx.connect' }
            }
          }
        ]
      },
      {
        path: 'stories',
        component: load('stories/StoriesIndex'),
        meta: { section: 'stories', pageTitle: 'Stories | Hypefactors' },
        children: [
          {
            path: '',
            component: load('stories/StoryList'),
            name: 'story.list'
          },
          {
            path: 'create',
            component: load('stories/StoryEditV2'),
            name: 'story.create',
            props: true,
            meta: {
              permission: 'story.create',
              markAsActive: 'story.list'
            }
          },
          {
            path: 'story/:storyId/edit',
            component: load('stories/StoryEditV2'),
            name: 'story.edit',
            props: true,
            meta: {
              permission: 'story.update',
              markAsActive: 'story.list'
            }
          },
          {
            name: 'story.facts',
            path: 'story/:storyId/facts',
            component: load('stories/StoryFacts'),
            props: true
          },
          {
            path: 'newsroom-embed/:newsroomId',
            component: load('stories/NewsroomEmbed'),
            name: 'story.newsroom-embed',
            props: true
          },
          {
            name: 'story.newsrooms',
            path: 'newsrooms',
            component: load('stories/Newsrooms'),
            meta: {
              permission: 'newsroom.create'
            }
          },
          {
            name: 'story.newsrooms.facts',
            path: 'newsrooms/:newsroomId/facts',
            component: load('stories/NewsroomFacts'),
            props: true
          },
          {
            name: 'stories.email',
            path: 'email',
            component: load('emails/ComposeEmail')
          }
        ]
      },
      {
        path: 'emails',
        component: load('emails/EmailsIndex'),
        meta: { section: 'emails', pageTitle: 'Emails | Hypefactors' },
        children: [
          {
            path: 'new',
            name: 'emails.new',
            component: load('emails/ComposeEmail')
          },
          {
            path: ':emailId',
            name: 'emails.edit',
            component: load('emails/ComposeEmail')
          }
        ]
      },
      {
        path: 'reports',
        component: load('report/ReportIndex'),
        name: 'reports',
        meta: { section: 'report', pageTitle: 'Report Archive | Hypefactors' },
        redirect: { name: 'reports.list' },
        children: [
          {
            path: '/',
            name: 'reports.list',
            component: load('report/Reports'),
            meta: {
              permission: 'performance.report',
              permissionFallbackType: 'report',
              permissionFallbackTo: { name: 'cx.measure' }
            }
          }
        ]
      },
      {
        path: 'trends',
        component: load('trends/TrendsEmbed'),
        meta: { section: 'trends', pageTitle: 'Trends | Hypefactors' },
        name: 'trends.index'
      },
      {
        path: 'settings',
        component: load('settings/Page'),
        meta: { section: 'settings', pageTitle: 'Settings | Hypefactors' },
        name: 'settings',
        redirect: { name: 'settings.company' },
        children: [
          {
            path: 'company',
            component: load('settings/company/Page'),
            name: 'settings.company',
            redirect: { name: 'settings.company.organisation' },
            children: [
              {
                path: 'organisation',
                component: load('settings/company/Organisation'),
                name: 'settings.company.organisation',
                meta: {
                  permission: 'organisation.update',
                  pageTitle: 'Settings | Company | Hypefactors'
                }
              },
              {
                path: 'export-organisation-clippings',
                component: load('settings/company/ExportOrganisationClippings'),
                name: 'settings.company.export-organisation-clippings',
                meta: {
                  permission: 'organisation.update',
                  pageTitle: 'Settings | Export Clippings | Hypefactors'
                }
              },
              {
                path: 'trend-groups',
                component: load('settings/company/TrendGroupsSorting'),
                name: 'settings.company.trend-groups',
                meta: {
                  permission: 'organisation.update',
                  pageTitle: 'Settings | Trend Groups | Hypefactors'
                }
              }
            ]
          },
          {
            path: 'brand',
            component: load('settings/brand/Page'),
            name: 'settings.brand',
            redirect: { name: 'settings.brand.brand' },
            children: [
              {
                path: 'brand',
                component: load('settings/brand/Brand'),
                name: 'settings.brand.brand',
                meta: {
                  permission: 'brand.update'
                }
              },
              {
                path: 'create',
                component: load('settings/brand/Brand'),
                name: 'settings.brand.create',
                meta: {
                  permission: 'brand.create'
                }
              },
              {
                path: 'tier',
                component: load('settings/brand/MediaOutlets'),
                name: 'settings.brand.tiers',
                meta: {
                  permission: 'brand.update'
                }
              },
              {
                path: 'members',
                component: load('settings/brand/Members'),
                name: 'settings.brand.members',
                meta: {
                  permission: 'brand.update'
                }
              },
              {
                path: 'rss',
                component: load('settings/brand/RSS'),
                name: 'settings.brand.rss',
                meta: {
                  permission: 'brand.update'
                }
              }
            ]
          },
          {
            path: 'personal',
            name: 'settings.personal',
            component: load('settings/personal/Page'),
            redirect: { name: 'settings.personal.preferences' },
            children: [
              {
                path: 'preferences',
                name: 'settings.personal.preferences',
                component: load('settings/personal/Preferences'),
                meta: {
                  permission: 'user.update'
                }
              },
              {
                path: 'email',
                name: 'settings.personal.email',
                component: load('settings/personal/EmailSettings'),
                meta: {
                  permission: 'emails.settings.update'
                }
              },
              {
                path: 'brands',
                name: 'settings.personal.brands',
                component: load('settings/personal/BrandsSettings')
              },
              {
                path: 'media-notifications',
                name: 'settings.personal.media_notifications',
                component: load('settings/personal/MediaNotifications')
              }
            ]
          },
          {
            path: 'billing',
            component: load('settings/billing/Page'),
            name: 'settings.billing',
            redirect: { name: 'settings.billing.billing' },
            children: [
              {
                path: 'billing',
                component: load('settings/billing/Billing'),
                name: 'settings.billing.billing',
                meta: {
                  permission: 'organisation.update'
                }
              },
              {
                path: 'invoices',
                component: load('settings/billing/Invoices'),
                name: 'settings.billing.invoices',
                meta: {
                  permission: 'organisation.update'
                }
              },
              {
                path: 'users',
                component: load('settings/billing/Users'),
                name: 'settings.billing.users',
                meta: {
                  permission: 'organisation.update'
                }
              },
              {
                path: 'plans',
                component: load('settings/billing/Plans'),
                name: 'settings.billing.plans',
                meta: {
                  permission: 'organisation.update'
                }
              }
            ]
          },
          {
            path: 'user/:user_id',
            component: load('settings/SettingsCompany'),
            redirect: { name: 'settings.user.permissions' },
            children: [
              {
                path: 'permissions',
                name: 'settings.user.permissions',
                component: load('settings/user/Permissions')
              }
            ]
          }
        ]
      },
      {
        path: 'cx',
        component: load('cx/InformationPagesIndex'),
        name: 'cx',
        redirect: { name: 'cx.features' },
        children: [
          {
            path: 'features/measure',
            name: 'cx.measure',
            component: load('cx/Measure'),
            meta: { pageTitle: 'Measure' }
          },
          {
            path: 'features/connect',
            name: 'cx.connect',
            component: load('cx/Connect'),
            meta: { pageTitle: 'Connect' }
          },
          {
            path: 'features/dashboard',
            name: 'cx.dashboard',
            component: load('cx/Dashboard'),
            meta: { pageTitle: 'Dashboard' }
          },
          {
            path: 'features/monitor',
            name: 'cx.monitor',
            component: load('cx/Monitor'),
            meta: { pageTitle: 'Monitor' }
          },
          {
            path: 'features',
            name: 'cx.features',
            component: load('cx/Features'),
            meta: { pageTitle: 'Features' }
          },
          {
            path: 'pricing',
            name: 'cx.pricing',
            component: load('cx/Pricing'),
            meta: { pageTitle: 'Pricing' }
          },
          {
            name: 'cx.manage-subscription',
            path: 'manage-subscription',
            component: load('cx/ManageSubscription'),
            meta: { pageTitle: 'Choose Subscription' }
          },
          {
            name: 'cx.checkout',
            path: 'checkout',
            component: load('cx/Checkout'),
            meta: { pageTitle: 'Checkout' }
          },
          {
            path: 'contact_us',
            name: 'cx.contact_us',
            component: load('cx/ContactUs'),
            meta: { pageTitle: 'Contact Us' }
          }
        ]
      },
      {
        path: 'oauth/:provider/callback',
        name: 'socialNetworkCallback',
        component: load('auth/SocialNetworkCallback'),
        props: true
      },
      {
        name: 'staff.oauth.twitter',
        path: 'oauth/staff/twitter/callback',
        component: load('auth/StaffTwitterCallback')
      },
      {
        path: 'fetch-instagram-token',
        name: 'socialNetworkCallback.instagram',
        component: load('auth/SocialNetworkCallback'),
        props: {
          provider: 'instagram'
        }
      },
      {
        path: 'staff',
        component: load('staff/Staff'),
        meta: { section: 'staff' },
        name: 'staff',
        beforeEnter: allowStaffOnly,
        redirect: { name: 'staff.manual-user' },
        children: [
          {
            path: 'connect/media-profiles',
            component: load('staff/Connect/MediaProfiles/_'),
            children: [
              {
                path: '',
                name: 'staff.connect.media-profiles',
                component: load('staff/Connect/MediaProfiles/List')
              },
              {
                path: 'create',
                name: 'staff.connect.media-profiles.create',
                component: load('staff/Connect/MediaProfiles/Manage')
              },
              {
                path: 'edit/:mediaProfileId',
                name: 'staff.connect.media-profiles.edit',
                props: true,
                component: load('staff/Connect/MediaProfiles/Manage')
              }
              // {
              //   path: 'import',
              //   name: 'staff.connect.media-profiles.import',
              //   component: load('staff/Connect/MediaProfiles/Import')
              // }
            ]
          },
          {
            path: 'connect/relations',
            component: load('staff/Connect/Relations/_'),
            children: [
              {
                path: 'import',
                name: 'staff.connect.relations.import',
                component: load('staff/Connect/Relations/Import')
              },
              {
                path: 'delete',
                component: load('staff/Connect/Relations/Delete'),
                name: 'staff.connect.relations.delete'
              }
            ]
          },

          {
            path: 'create-user',
            component: load('staff/user/CreateUser'),
            name: 'staff.create-user'
          },
          {
            path: 'delete-user',
            component: load('staff/user/DeleteUser'),
            name: 'staff.delete-user'
          },
          {
            path: 'restore-user',
            component: load('staff/user/RestoreUser'),
            name: 'staff.restore-user'
          },
          {
            path: 'impersonate-user',
            component: load('staff/user/ImpersonateUser'),
            name: 'staff.impersonate-user'
          },
          {
            path: 'newsrooms',
            component: load('staff/StaffNewsrooms'),
            name: 'staff.newsrooms'
          },
          {
            path: 'restore-brand',
            component: load('staff/RestoreBrand'),
            name: 'staff.restore-brand'
          },
          {
            path: 'manage_streams',
            component: load('staff/StaffStreams'),
            name: 'staff.manage_streams'
          },
          {
            path: 'media-outlets',
            name: 'staff.media-outlets',
            component: load('staff/StaffMediaOutlets'),
            redirect: { name: 'staff.media-outlets.index' },
            children: [
              {
                name: 'staff.media-outlets.index',
                path: '',
                component: load('staff/mediaOutlets/MediaOutletIndex')
              },
              {
                name: 'staff.media-outlets.create',
                path: 'create',
                component: load('staff/mediaOutlets/MediaOutletManager'),
                props: true
              },
              {
                name: 'staff.media-outlets.edit',
                path: ':mediaOutletId/edit',
                component: load('staff/mediaOutlets/MediaOutletManager'),
                props: true
              }
            ]
          },
          {
            path: 'organisations',
            component: load('staff/StaffOrganisations'),
            name: 'staff.organisations'
          },
          {
            path: 'enterprise_requests',
            component: load('staff/EnterpriseRequests'),
            name: 'staff.enterprise_requests'
          },
          {
            path: 'set-email-logo',
            component: load('staff/SetEmailLogo'),
            name: 'staff.set_email_logo'
          },
          {
            name: 'staff.authenticate-twitter-profiles',
            path: 'authenticate-twitter',
            component: load('staff/AuthenticateTwitter')
          },
          {
            path: 'qa-mentions',
            name: 'staff.qa_mentions',
            component: load('staff/MentionsQA')
          },
          {
            path: 'print-upload',
            name: 'staff.print_upload',
            component: load('staff/PrintUpload')
          }
        ]
      }
    ]
  },
  { path: '*', name: 'not-found', component: load('errors/404') }
]
